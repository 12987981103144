/*
 * File: stories-handler.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: February 5, 2025 at 12:48 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Constants
 */

const GROUNDING_EXERCISES = [
	{ id: 'grounding_exercise_1', components: [{ id: 'exercise_1/exercise_1_initial' }] },
	{ id: 'grounding_exercise_2', components: [{ id: 'exercise_2/exercise_2_initial' }] },
	{ id: 'grounding_exercise_3', components: [{ id: 'exercise_3/exercise_3_initial' }] },
	{ id: 'grounding_exercise_4', components: [{ id: 'exercise_4/exercise_4_initial' }] },
	{ id: 'grounding_exercise_5', components: [{ id: 'exercise_5/exercise_5_initial' }] },
];


/**
 * Requires
 */

// Modules
const Masonry = require('masonry-layout');
const imagesLoaded = require('imagesloaded');

// Utilities
const utilities = require('../modules/utilities');
const metrics = require('../modules/metrics');
const { recordHotjarState, recordGAEvent } = require('../modules/analytics');

// Helpers
const { renderStories } = require('../helpers/stories-renderer');

// Handlers
const reactionModalHandler = require('./components/modals/reaction-community-modal-handler');

// Templates
const groundingContainerTemplate = require('../../../views/partials/stories/grounding/grounding_container.ejs');
const storyCard = require('../../../views/partials/stories/story_card.ejs');
const mantraCard = require('../../../views/partials/stories/mantra_card.ejs');
const reactionBadge = require('../../../views/partials/stories/reaction_badge.ejs');

// Dynamic Templates
for (let i = 0; i < GROUNDING_EXERCISES.length; i += 1) {
	for (let j = 0; j < GROUNDING_EXERCISES[i].components.length; j += 1) {
		// eslint-disable-next-line import/no-dynamic-require, global-require
		const template = require(`../../../views/partials/stories/grounding/${GROUNDING_EXERCISES[i].components[j].id}.ejs`);
		GROUNDING_EXERCISES[i].components[j].component = template;
	}
}

// Constants
const {
	CONSENT_PERFORMANCE_ENABLED_COOKIE,
	STORIES_VIEWED_COOKIE,
	ACTIVE_STORIES_FILTERS_COOKIE,
	ACTIVE_STORIES_MEDIA_COOKIE,
	ACTIVE_STORIES_FILTERS_QUERY,
	ACTIVE_STORIES_MEDIA_QUERY,
	STORY_MEDIA_TYPES,
	SCROLL_LOAD_TOP,
	SCROLL_LOAD_BOTTOM,
	USER_ACTIONS,
	REACTIONS,
	SOURCES
} = require('../../../constants/general.constants');

// jQuery Arguments
imagesLoaded.makeJQueryPlugin($);


/**
 * Global Variables
 */

let masonry;
let storiesArray = [];
let displayArray = [];
let filterOptions = [];
let mediumOptions = [];
let availableTags = [];
let displayedSupportIds = [];
let globalState = null;
let localeObj = null;
let reactionSchema = null;
let updateSchema = null;
let shouldFetchStories = true;
let isGroundingPresented = true;
let storyFetchPage = 0;
let currentGroundingExercise;
let currentGroundingStep;
let scrollDebounce;
let stateObj;
const completedGroundingExercises = [];


/**
 * Helpers
 */

const setReadStoryCookie = (minutes) => {
	if (utilities.isConsentLevelEnabled(CONSENT_PERFORMANCE_ENABLED_COOKIE)) {
		const now = new Date();
		now.setMinutes(now.getMinutes() + minutes);
		document.cookie = `${STORIES_VIEWED_COOKIE}=true; expires=${now.toUTCString()}; path=/; SameSite=None; Secure`;
	}
};

const setActiveFilterCookies = () => {
	if (utilities.isConsentLevelEnabled(CONSENT_PERFORMANCE_ENABLED_COOKIE)) {

		// Create expiration
		const expiration = new Date();
		expiration.setHours(expiration.getHours() + 1);

		// Set active filter cookies
		const filters = filterOptions.map((option) => option.tag_id).join(',');
		if (filters != null && filters !== '') {
			document.cookie = `${ACTIVE_STORIES_FILTERS_COOKIE}=${filters}; expires=${expiration.toUTCString()}; path=/; SameSite=None; Secure`;
		} else {
			document.cookie = `${ACTIVE_STORIES_FILTERS_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; SameSite=None; Secure`;
		}

		// Set active media cookies
		const media = mediumOptions.join(',');
		if (media != null && media !== '') {
			document.cookie = `${ACTIVE_STORIES_MEDIA_COOKIE}=${media}; expires=${expiration.toUTCString()}; path=/; SameSite=None; Secure`;
		} else {
			document.cookie = `${ACTIVE_STORIES_MEDIA_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; SameSite=None; Secure`;
		}
	}
};

const setActiveFilterQuery = () => {

	// Get current search parameters
	const searchParams = new URLSearchParams(window.location.search);

	// Set active filter query
	const filters = filterOptions.map((option) => option.tag_id).join(',');
	if (filters != null && filters !== '') {
		searchParams.set(ACTIVE_STORIES_FILTERS_QUERY, filters);
	} else {
		searchParams.delete(ACTIVE_STORIES_FILTERS_QUERY);
	}

	// Set active media query
	const media = mediumOptions.join(',');
	if (media != null && media !== '') {
		searchParams.set(ACTIVE_STORIES_MEDIA_QUERY, media);
	} else {
		searchParams.delete(ACTIVE_STORIES_MEDIA_QUERY);
	}

	// Push new state
	let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${searchParams.toString()}`;
	newUrl = newUrl.replace(/\?$/, '');
	window.history.pushState({ path: newUrl }, '', newUrl);
};

const removeDuplicates = (stories) => Array.from(new Set(stories.map((a) => a.story_id))).map((id) => stories.find((a) => a.story_id === id));

const isPositionToLoad = () => $(window).scrollTop() + $(window).height() > $(document).height() - $('#footer-container').height() + SCROLL_LOAD_TOP && $(window).scrollTop() + $(window).height() < $(document).height() - $('#footer-container').height() + SCROLL_LOAD_BOTTOM;


/**
 * Grounding Exercise Handlers
 */

const showGroundingExerciseState = async () => {
	if (!isGroundingPresented) {

		// Render template
		const containerTemplate = groundingContainerTemplate(globalState);

		// Add hide stories filter
		$('#story-container').addClass('hide-stories');

		// Hide filters
		$('#filter-container').addClass('hide-filter');
		$('#filter-buttons-container').addClass('hide');

		// Activate overlay (experimental)
		$('#stories-overlay').addClass('active');

		// Prepend grounding card
		const $items = $(containerTemplate);
		$('#story-container').prepend($items);
		masonry.prepended($items);

		// Layout cards
		masonry.layout();

		// Update state
		isGroundingPresented = true;

		// Inform Hotjar of state change
		recordHotjarState('stateChange', '/#show_grounding');
	}
};

const handleGroundingExerciseStep = async () => {

	// Get components
	const { components } = currentGroundingExercise;
	currentGroundingStep += 1;

	// Get component template
	const template = components[currentGroundingStep].component;

	// Get container
	const container = $('#grounding-container');

	// Fade out
	container.fadeOut('slow', () => {

		// Change content
		container.html(template({ locale: localeObj }));

		// Fade in
		container.fadeIn('slow', () => { });

		// Layout cards
		setTimeout(() => {
			masonry.layout();
		}, 100);
	});
};

const finishGroundingExercise = () => {
	if (isGroundingPresented) {

		// Remove grounding card
		masonry.remove($('#grounding-card'));

		// Remove hide stories filter
		$('#story-container').removeClass('hide-stories');

		// Show filters
		$('#filter-container').removeClass('hide-filter');
		$('#filter-buttons-container').removeClass('hide');

		// Deactivate overlay (experimental)
		$('#stories-overlay').removeClass('active');

		// Set read cookie (5 min)
		setReadStoryCookie(5);

		// Layout cards
		masonry.layout();

		// Update state
		isGroundingPresented = false;

		// Inform Hotjar of state change
		recordHotjarState('stateChange', '/#hide_grounding');
	}
};

const getRandomGroundingExercise = () => {

	// Set up completed array
	if (completedGroundingExercises.length === GROUNDING_EXERCISES.length) {
		completedGroundingExercises.shift();
	}

	// Set up available array
	const availableArray = [];
	GROUNDING_EXERCISES.forEach((exercise) => {
		if (completedGroundingExercises.indexOf(exercise.id) === -1) {
			availableArray.push(exercise);
		}
	});

	// Get grounding exercise
	return availableArray[Math.floor(Math.random() * availableArray.length)];
};

const startGroundingExercise = () => {

	// Select grounding exercise to start
	currentGroundingExercise = getRandomGroundingExercise();

	// Store state
	completedGroundingExercises.push(currentGroundingExercise.id);

	// Reset state
	currentGroundingStep = -1;

	// Handle initial grounding step
	handleGroundingExerciseStep();

	// Record action
	metrics.recordAction(USER_ACTIONS.STARTED_GROUNDING_EXERCISE, {}, localeObj.locale_id, stateObj);
};


/**
 * Stories Handlers
 */

function layoutStories() {

	// Layout masonry
	masonry.layout();

	// Determine reactions padding state
	const screenWidth = $(window).width();
	$('.grid-item').each(function (storyIndex) {
		let showReactionsPadding = false;
		const reactions = $(this).find('.reaction-badge');
		if (screenWidth > 1023) {
			if (storyIndex > 2 && reactions.length > 0) showReactionsPadding = true;
		} else if (screenWidth > 639) {
			if (storyIndex > 1 && reactions.length > 0) showReactionsPadding = true;
		} else if (storyIndex > 0 && reactions.length > 0) showReactionsPadding = true;
		if (showReactionsPadding === true && !$(this).hasClass('reactions-margin')) {
			$(this).addClass('reactions-margin');
		}
	});
}

async function renderStoriesContent(stories, shouldClear) {

	// Render content
	const elements = await renderStories(stories, localeObj, globalState.state, reactionSchema, updateSchema, {
		storyCardCompiler: () => storyCard,
		mantraCardCompiler: () => mantraCard
	});

	// Add stories
	const $items = $(elements);
	if (shouldClear) {
		$('#story-container').html($items);
		masonry.reloadItems();
	} else {
		$('#story-container').append($items);
		masonry.appended($items);
	}

	// Layout stories
	layoutStories();

	// Re-layout after images load
	$('#story-container').imagesLoaded(() => {
		layoutStories();
	});
}

async function fetchStoriesContent(appended) {

	// Update state
	shouldFetchStories = false;

	// Get current fetch page number
	storyFetchPage += 1;

	// Fetch stories
	const params = {
		page: storyFetchPage,
		filter_options: {
			tags: filterOptions.map((option) => option.tag_id),
			media: mediumOptions
		},
		locale_id: localeObj.locale_base_id,
		displayed_support_ids: displayedSupportIds
	};
	const { stories, supportIds, reactionSchema: newSchema } = await Parse.Cloud.run('fetchStoryPreviewsWithParameters', params);

	// Update objects
	reactionSchema = newSchema;
	displayedSupportIds = supportIds;

	// Deduplicate support ids
	displayedSupportIds = [...new Set(displayedSupportIds)];

	// Update state
	if (stories != null && stories.length > 0) {
		if (appended) {

			// Append stories to array
			displayArray = displayArray.concat(stories);

			// Return
			return;
		}

		// Append stories to array
		displayArray = removeDuplicates(displayArray.concat(stories));
		storiesArray = removeDuplicates(storiesArray.concat(stories));

		// Render content
		renderStoriesContent(stories, false);

		// Update state
		shouldFetchStories = true;
	}
}


/**
 * Filter Handlers
 */

const updateFilterRowVisibility = () => {

	// Get container
	const container = $('#filter-container');
	const holder = $('#filter-holder');

	// Create filter options
	let html = '';
	html += filterOptions.map((option) => `<div class='filter-tag'><span>${option.title}</span><i name='filter-option-remove' data-id='${option.tag_id}' data-type='tag' class='far fa-times-circle animate'></i></div>`).join('');
	html += mediumOptions.map((option) => {
		let title = '';
		switch (option) {
			case STORY_MEDIA_TYPES.NARRATIVE:
				title = localeObj.content.PAGE_STORIES_MEDIUM_NARRATIVE;
				break;
			case STORY_MEDIA_TYPES.ARTWORK:
				title = localeObj.content.PAGE_STORIES_MEDIUM_ARTWORK;
				break;
			default:
				break;
		}
		if (title !== '') {
			return `<div class='filter-tag'><span>${title}</span><i name='filter-option-remove' data-id='${option}' data-type='medium' class='far fa-times-circle animate'></i></div>`;
		}
		return '';
	}).join('');

	// Set filter options
	holder.html(html);

	// Update container visibility
	if (filterOptions.length > 0 || mediumOptions.length > 0) {
		container.show();
	} else {
		container.hide();
	}

	// Update filter button
	const filterCount = filterOptions.length === 0 ? '' : `(${filterOptions.length})`;
	$('#stories-general-filter-count').html(filterCount);
};

const updateFilterOptionsVisibility = () => {

	// Update general filter check boxes
	filterOptions.forEach((option) => {
		$(`[name='filter-option-toggle'][data-type='tag'][data-id='${option.tag_id}']`).addClass('active');
	});

	// Update medium filter check boxes
	mediumOptions.forEach((option) => {
		$(`[name='filter-option-toggle'][data-type='medium'][data-id='${option}']`).addClass('active');
	});
};

const applyCurrentFilters = async () => {

	// Set active filter cookies
	setActiveFilterCookies();

	// Set active filter query
	setActiveFilterQuery();

	// Update filter row visibility
	updateFilterRowVisibility();

	// Reset state
	storyFetchPage = -1;
	shouldFetchStories = true;

	// Clear display array
	displayArray = [];

	// Fetch stories
	await fetchStoriesContent(true);

	// Deduplicate array
	displayArray = removeDuplicates(displayArray);

	// Render stories
	renderStoriesContent(displayArray, true);

	// Update state
	shouldFetchStories = true;
};

const handleGeneralFilterPosition = () => {

	// Get elements
	const filterButton = $('#stories-general-filter-button');

	// Create position parameters
	const top = filterButton.position().top + filterButton.outerHeight() + 10;

	// Set offset position
	$('#general-filter-dropdown').css('top', top);
};


/**
 * Reaction Handlers
 */

const addReactionForStory = async (element) => {

	// Animate reaction selection
	$(element).addClass('reaction-bounce');

	// Hide reaction drawer
	setTimeout(() => {

		// Remove bounce animation
		$(element).removeClass('reaction-bounce');

		// Get element references
		const parent = $(element).parent().parent();

		// Hide reactions
		$(element).parent().addClass('hide-reactions');

		// Remove and re-add elements
		const reactions = $(element).parent().detach();
		$(parent).append(reactions);

		// Clear classes
		setTimeout(() => {
			$(element).parent().removeClass('hide-reactions');
		}, 500);
	}, 500);

	// Get parameters
	const storyId = $(element).parent().data('id');
	let code = null;
	if ($(element).hasClass('reaction-a-icon')) code = 'reaction_a';
	else if ($(element).hasClass('reaction-b-icon')) code = 'reaction_b';
	else if ($(element).hasClass('reaction-c-icon')) code = 'reaction_c';
	else if ($(element).hasClass('reaction-d-icon')) code = 'reaction_d';
	else if ($(element).hasClass('reaction-e-icon')) code = 'reaction_e';

	// Check if reaction is new
	reactionSchema = utilities.getLocalReactionSchema(reactionSchema);
	if (reactionSchema[storyId] == null || reactionSchema[storyId].indexOf(code) === -1) {

		// Save reaction locally
		utilities.setReactionToLocalSchema(storyId, code, reactionSchema);

		// Get reaction container
		const container = $(element).parent().parent().parent()
			.prev('.reaction-badge-container');

		// Render new badge
		const indexes = ['first',
			'second',
			'third',
			'fourth',
			'fifth'];
		const html = reactionBadge({
			reaction: {
				position: indexes[container.children().length],
				icon: REACTIONS[code].icon,
				legend: localeObj.content.REACTIONS[code],
				shouldAnimate: true
			}
		});

		// Add badge to reactions
		container.append(html);

		// Get embed cookie
		const embedId = utilities.getBrowserEmbedId(stateObj);

		// Create parameters
		const params = {
			source: SOURCES.STORIES,
			entity_id: storyId,
			reaction_code: code,
			locale_id: localeObj.locale_id,
			embed_id: embedId,
			url_path: window.location.pathname,
			performanceConsent: utilities.isConsentLevelEnabled(CONSENT_PERFORMANCE_ENABLED_COOKIE) ? 'true' : 'false'
		};

		// Save reaction
		Parse.Cloud.run('recordReactionWithParameters', params);

		// If first reaction on client, show reaction modal
		if (Object.keys(reactionSchema).length === 0 && reactionSchema.constructor === Object) {
			reactionModalHandler.showModal(code);
		}
	}
};

const removeReactionForStory = (element) => {

	// Get elements
	const badge = $(element).parent().parent().parent();
	const badgeContainer = badge.parent();

	// Get parameters
	const storyId = badgeContainer.data('id');
	let code = null;
	if ($(badge).hasClass('reaction-a-icon')) code = 'reaction_a';
	else if ($(badge).hasClass('reaction-b-icon')) code = 'reaction_b';
	else if ($(badge).hasClass('reaction-c-icon')) code = 'reaction_c';
	else if ($(badge).hasClass('reaction-d-icon')) code = 'reaction_d';
	else if ($(badge).hasClass('reaction-e-icon')) code = 'reaction_e';

	// Remove from local schema
	utilities.removeReactionFromLocalSchema(storyId, code, reactionSchema);

	// Remove badge
	badge.remove();

	// Refactor order classes
	$(badgeContainer)
		.children()
		.each(function (index) {
			const indexes = ['first',
				'second',
				'third',
				'fourth',
				'fifth'];
			$(this).removeClass(indexes);
			$(this).addClass(indexes[index]);
		});
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	/**
	 * Grounding Exercise Actions
	 */

	// Handle start grounding
	$(document).on('click', '#start-grounding-button', async () => {

		// Record GA event
		recordGAEvent('event', 'grounding_exercise', 'start');

		// Start grounding exercise
		startGroundingExercise();
	});

	// Handle skip grounding
	$(document).on('click', '#skip-grounding-button', () => {

		// Record GA event
		recordGAEvent('event', 'grounding_exercise', 'skip');

		// Finish grounding exercise
		finishGroundingExercise();
	});

	// Handle finish grounding
	$(document).on('click', '#finish-grounding-button', () => {

		// Record GA event
		recordGAEvent('event', 'grounding_exercise', 'finish');

		// Finish grounding exercise
		finishGroundingExercise();
	});

	// Handle show grounding
	$(document).on('click', '#show-grounding-activity', () => {

		// Hide mobile menu
		if ($('#dropdown-menu').hasClass('show')) {
			$('#dropdown-menu').toggleClass('show');
			$('#menu-arrow-open').toggleClass('hide');
			$('#menu-arrow-close').toggleClass('hide');
		}

		// Record GA event
		recordGAEvent('event', 'grounding_exercise', 'show');

		// Show grounding exercise
		showGroundingExerciseState();
	});

	// Handle start another grounding
	$(document).on('click', '#another-grounding-button', () => {

		// Record GA event
		recordGAEvent('event', 'grounding_exercise', 'another');

		// Start another grounding exercise
		startGroundingExercise();
	});


	/**
	 * Story Card Actions
	 */

	// Handle read story
	$(document).on('click', "a[name = 'read-story']", function (event) {

		// Stop link propagation
		event.preventDefault();

		// Set read story cookie (10 min)
		setReadStoryCookie(10);

		// Fire event
		window.location.href = $(this).attr('href');
	});

	// Handle click on partner link
	$(document).on('click', "a[name='partner-community']", (event) => {

		// Stop link propagation
		event.stopPropagation();
	});

	// Handle click on trigger toggle
	$(document).on('click', "span[name='trigger-toggle']", function (event) {

		// Stop click propagation
		event.stopPropagation();

		// De-obfuscate image
		$(this).parent().parent().removeClass('warning');

		// Remove trigger overlay
		$(this).parent().remove();
	});

	// Handle read story
	$(document).on('click', '[data-card="story-card"]', function () {
		if (isGroundingPresented !== true) {

			// Set read story cookie (10 min)
			setReadStoryCookie(10);

			// Fire event
			window.location.href = `${localeObj.locale_path}/story/${$(this).data('link')}`;
		}
	});


	/**
	 * Filter Actions
	 */

	// Handle filter button
	$(document).on('click', '#stories-medium-filter-button', function () {

		// Toggle selected state
		$(this).toggleClass('active');
		$('#stories-general-filter-button').removeClass('active');

		// Toggle dropdown state
		$('#medium-filter-dropdown').toggleClass('show');
		$('#general-filter-dropdown').removeClass('show');
	});

	// Handle filter button
	$(document).on('click', '#stories-general-filter-button', function () {

		// Toggle selected state
		$(this).toggleClass('active');
		$('#stories-medium-filter-button').removeClass('active');

		// Toggle dropdown state
		$('#general-filter-dropdown').toggleClass('show');
		$('#medium-filter-dropdown').removeClass('show');
	});

	// Handle select / deselect filter option
	$(document).on('click', "[name='filter-option-toggle']", function () {

		// Get parameters
		const id = $(this).data('id');
		const type = $(this).data('type');

		// Toggle selected state
		$(this).toggleClass('active');

		// Update filter state
		if (type === 'tag') {
			if (filterOptions.some((option) => option.tag_id === id)) {
				filterOptions = filterOptions.filter((option) => option.tag_id !== id);
			} else {
				for (let i = 0; i < availableTags.length; i += 1) {
					if (availableTags[i].tag_id === id) {
						filterOptions.push(availableTags[i]);
						break;
					}
				}
			}
		} else if (type === 'medium') {
			if (mediumOptions.includes(id)) {
				mediumOptions = mediumOptions.filter((option) => option !== id);
			} else {
				mediumOptions.push(id);
			}
		}

		// Apply current filters
		applyCurrentFilters();
	});

	// Handle click on remove specific filter
	$(document).on('click', "[name='filter-option-remove']", function () {

		// Get parameters
		const id = $(this).data('id');
		const type = $(this).data('type');

		// Remove item
		$(this).parent().remove();

		// Update check box
		$(`[name='filter-option-toggle'][data-type='${type}'][data-id='${id}']`).toggleClass('active');

		// Update filter state
		if (type === 'tag') {
			filterOptions = filterOptions.filter((option) => option.tag_id !== id);
		} else if (type === 'medium') {
			mediumOptions = mediumOptions.filter((option) => option !== id);
		}

		// Apply current filters
		applyCurrentFilters();
	});

	// Handle click on remove all filters
	$(document).on('click', '#general-filter-clear', () => {
		if (filterOptions.length > 0) {

			// Clear filters
			filterOptions = [];

			// Update check boxes
			$('[name=\'filter-option-toggle\'][data-type=\'tag\']').removeClass('active');

			// Apply current filters
			applyCurrentFilters();
		}
	});


	/**
	 * Reaction Actions
	 */

	// Handle touch start on reaction
	$(document).on('touchstart', "button[name='reaction-selector']", function (event) {

		// Stop propagation
		event.stopPropagation();

		// Make element active
		$(this).focus();
	});

	// Handle click on reaction
	$(document).on('click', "button[name='reaction-selector']", function (event) {

		// Stop propagation
		event.stopPropagation();

		// Handle reaction for story
		addReactionForStory(this);
	});

	// Handle click on reaction remove
	$(document).on('click', "i[name='reaction-remove-selector']", function (event) {

		// Stop propagation
		event.stopPropagation();

		// Handle reaction for story
		removeReactionForStory(this);
	});

	// Handle click on support button
	$(document).on('click', "button[name='support-button']", (event) => {

		// Stop propagation
		event.stopPropagation();
	});


	/**
	 * General Actions
	 */

	// Handle document scroll
	$(window).scroll(() => {

		// Run in debounce
		clearTimeout(scrollDebounce);
		scrollDebounce = setTimeout(() => {

			// Check if user has scrolled into range
			if (isPositionToLoad()) {

				// Fetch stories
				if (shouldFetchStories && !isGroundingPresented) {
					fetchStoriesContent(false);
				}
			}
		}, 50);
	});

	// Handle specific element positioning
	$(document).ready(() => {

		// Handle general filter position
		handleGeneralFilterPosition();

		// Handle position on window resize
		$(window).resize(() => {
			handleGeneralFilterPosition();
		});
	});
};

/**
 * Input Handlers
 */

const createInputHandlers = () => {};


/**
 * Init
 */

exports.initialize = async (data) => {
	globalState = data;

	// Set state
	stateObj = globalState.state;
	storiesArray = globalState.data.stories;
	displayArray = globalState.data.stories;
	isGroundingPresented = globalState.data.hide_stories;
	localeObj = globalState.locale;
	reactionSchema = globalState.data.reaction_schema;
	displayedSupportIds = globalState.data.support_ids;
	updateSchema = globalState.data.update_schema;

	// Initialize filters
	availableTags = data.data.tags;
	filterOptions = [];
	mediumOptions = [];

	// Get active filters from query
	const queryParams = data.state.query;
	if (queryParams[ACTIVE_STORIES_FILTERS_QUERY] != null) {
		queryParams[ACTIVE_STORIES_FILTERS_QUERY].split(',').forEach((filter) => {
			for (let i = 0; i < availableTags.length; i += 1) {
				if (availableTags[i].tag_id === filter) {
					filterOptions.push(availableTags[i]);
					break;
				}
			}
		});
	}
	if (queryParams[ACTIVE_STORIES_MEDIA_QUERY] != null) {
		const activeMedia = [];
		queryParams[ACTIVE_STORIES_MEDIA_QUERY].split(',').forEach((medium) => {
			if (utilities.isValueInObject(medium, STORY_MEDIA_TYPES)) activeMedia.push(medium);
		});
		if (activeMedia.length > 0) mediumOptions = [...new Set(activeMedia)];
	}

	// Get active filters from cookies
	if (filterOptions.length === 0) {
		const filterCookie = utilities.getCookie(ACTIVE_STORIES_FILTERS_COOKIE);
		if (filterCookie != null && filterCookie !== '') {
			filterCookie.split(',').forEach((filter) => {
				for (let i = 0; i < availableTags.length; i += 1) {
					if (availableTags[i].tag_id === filter) {
						filterOptions.push(availableTags[i]);
						break;
					}
				}
			});
		}
	}
	if (mediumOptions.length === 0) {
		const activeMedia = [];
		const mediaCookie = utilities.getCookie(ACTIVE_STORIES_MEDIA_COOKIE);
		if (mediaCookie != null && mediaCookie !== '') {
			mediaCookie.split(',').forEach((medium) => {
				if (utilities.isValueInObject(medium, STORY_MEDIA_TYPES)) activeMedia.push(medium);
			});
		}
		if (activeMedia.length > 0) mediumOptions = [...new Set(activeMedia)];
	}

	// Update filter options visibility
	updateFilterOptionsVisibility();

	// Update filter visibility
	updateFilterRowVisibility();

	// Set active filter cookies
	setActiveFilterCookies();

	// Set up Masonry
	masonry = new Masonry('.grid', {
		itemSelector: '.grid-item',
		horizontalOrder: true,
		isAnimated: true,
		percentPosition: true,
		hiddenStyle: { opacity: 0 },
	});

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Initialize reaction community modal
	reactionModalHandler.initialize();

	// Layout stories
	$(window).on('load', () => {
		layoutStories();
	});

	// Return
	return true;
};
