/*
 * File: utilities.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: February 5, 2025 at 1:08 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Modules
const validator = require('validator');

// Constants
const {
	EXCERPT_MIN,
	EXCERPT_MAX,
	OBFUSCATE_OPEN_CODE,
	OBFUSCATE_CLOSE_CODE,
	CONSENT_PERFORMANCE_ENABLED_COOKIE,
	REACTION_SCHEMA_COOKIE,
	UPDATES_SCHEMA_COOKIE,
	REACTIONS,
	EMBED_COOKIE
} = require('../../../constants/general.constants');


/**
 * Helpers
 */

const getAllIndexes = (arr, val) => {
	const indexes = [];
	let i = -1;
	while ((i = arr.indexOf(val, i + 1)) !== -1) { /* eslint-disable-line no-cond-assign */
		indexes.push(i);
	}
	return indexes;
};


/**
 * General Handlers
 */

exports.getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
	return undefined;
};

exports.removeCookie = (cname, stateObj) => {
	if (typeof document !== 'undefined') {
		const entityDomain = (stateObj.is_core) ? 'ourwave.org' : stateObj.entity.full_domain;
		const cookieDomain = (process.env.ENV === 'development') ? '' : `domain=${entityDomain}; `;
		document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ${cookieDomain}SameSite=None; Secure`;
	}
};


exports.getAllUrlParams = (url) => {

	// Get query string from url (optional) or window
	let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

	// We'll store the parameters here
	const obj = {};

	// If query string exists
	if (queryString) {

		// Stuff after # is not part of query string, so get rid of it
		[queryString] = queryString.split('#');

		// Split our query string into its component parts
		const arr = queryString.split('&');
		for (let i = 0; i < arr.length; i += 1) {

			// Separate the keys and the values
			const a = arr[i].split('=');

			// Set parameter name and value (use 'true' if empty)
			const paramName = a[0];
			const paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

			// If the paramName ends with square brackets, e.g. colors[] or colors[2]
			if (paramName.match(/\[(\d+)?\]$/)) {

				// Create key if it doesn't exist
				const key = paramName.replace(/\[(\d+)?\]/, '');
				if (!obj[key]) obj[key] = [];

				// If it's an indexed array e.g. colors[2]
				if (paramName.match(/\[\d+\]$/)) {

					// Get the index value and add the entry at the appropriate position
					const index = /\[(\d+)\]/.exec(paramName)[1];
					obj[key][index] = paramValue;
				} else {

					// Otherwise add the value to the end of the array
					obj[key].push(paramValue);
				}
			} else if (!obj[paramName]) { // We're dealing with a string

				// If it doesn't exist, create property
				obj[paramName] = paramValue;
			} else if (obj[paramName] && typeof obj[paramName] === 'string') {

				// If property does exist and it's a string, convert it to an array
				obj[paramName] = [obj[paramName]];
				obj[paramName].push(paramValue);
			} else {

				// Otherwise add the property
				obj[paramName].push(paramValue);
			}
		}
	}
	return obj;
};

exports.isConsentLevelEnabled = (level) => {
	const cookie = exports.getCookie(level);
	if (cookie != null) return cookie === 'true';
	return false;
};

exports.isValueInObject = (value, obj) => {
	const values = Object.values(obj);
	for (let i = 0; i < values.length; i += 1) {
		if (values[i] === value) {
			return true;
		}
	}
	return undefined;
};

exports.generateExcerptFromContent = (content) => {
	if (content != null) {

		// Define paragraphs with content
		const splitArray = content.split(/\n/g);
		const paragraphs = [];
		splitArray.forEach((paragraph) => {
			if (!validator.isEmpty(paragraph.replace(/\W/g, ''))) {
				paragraphs.push(paragraph);
			}
		});

		// Create excerpt from paragraphs
		let excerpt = '';
		let index = 0;
		while (excerpt.length < EXCERPT_MIN && index < paragraphs.length) {
			if (index > 0) excerpt += '\n';
			excerpt += paragraphs[index];
			index += 1;
		}

		// Truncate excerpt
		if (excerpt.length > EXCERPT_MAX) {
			let trimIndex = EXCERPT_MAX - 1;
			const indexesOfOpen = getAllIndexes(excerpt, OBFUSCATE_OPEN_CODE);
			const indexesOfClose = getAllIndexes(excerpt, OBFUSCATE_CLOSE_CODE);
			for (let i = 0; i < indexesOfOpen.length; i += 1) {
				const openIndex = indexesOfOpen[i];
				const closeIndex = indexesOfClose.length > i ? indexesOfClose[i] : openIndex;
				if (trimIndex >= openIndex && trimIndex <= closeIndex) {
					trimIndex = closeIndex + 2;
					break;
				}
			}
			excerpt = `${excerpt.substring(0, trimIndex).trim()}...`;
		}

		// Return excerpt
		return excerpt;
	}
	return '';
};

exports.getBrowserEmbedId = (stateObj) => {

	// Get embed id from cookies
	const embedId = exports.getCookie(EMBED_COOKIE);

	// Check if browser is currently inside frame
	if (window.self !== window.top) {

		// Return embed id
		return embedId;
	}

	// Remove embed id cookie if not in frame
	exports.removeCookie(EMBED_COOKIE, stateObj);

	// Return
	return null;
};


/**
 * Reaction Handlers
 */

exports.setReactionToLocalSchema = (storyId, code, existingSchema) => {
	if (exports.isConsentLevelEnabled(CONSENT_PERFORMANCE_ENABLED_COOKIE)) {
		const reactionSchema = exports.getLocalReactionSchema(existingSchema);
		if (reactionSchema[storyId] == null) reactionSchema[storyId] = [];
		if (reactionSchema[storyId].indexOf(code) === -1) {
			reactionSchema[storyId].push(code);
		}
		const schema = JSON.stringify(reactionSchema);
		document.cookie = `${REACTION_SCHEMA_COOKIE}=${schema}; expires=Thu, 01 Jan 2100 00:00:00 UTC; path=/; SameSite=None; Secure`;
	}
};

exports.removeReactionFromLocalSchema = (storyId, code, existingSchema) => {
	if (exports.isConsentLevelEnabled(CONSENT_PERFORMANCE_ENABLED_COOKIE)) {
		const reactionSchema = exports.getLocalReactionSchema(existingSchema);
		if (reactionSchema[storyId] != null) {
			const index = reactionSchema[storyId].indexOf(code);
			if (index > -1) reactionSchema[storyId].splice(index, 1);
			const schema = JSON.stringify(reactionSchema);
			document.cookie = `${REACTION_SCHEMA_COOKIE}=${schema}; expires=Thu, 01 Jan 2100 00:00:00 UTC; path=/; SameSite=None; Secure`;
		}
	}
};

exports.getLocalReactionSchema = (schema) => {
	if (schema != null && Object.keys(schema).length > 0) return schema;
	const cookie = exports.getCookie(REACTION_SCHEMA_COOKIE);
	try {
		if (cookie != null) return JSON.parse(cookie);
	} catch (error) {}
	return {};
};

exports.formatReactionArray = (reactions, locale, shouldAnimate) => {
	const reactionsArray = [];
	for (let i = 0; i < reactions.length; i += 1) {

		// Get index
		const indexes = ['first',
			'second',
			'third',
			'fourth',
			'fifth'];
		const index = indexes[i];

		// Get reaction
		const { icon } = REACTIONS[reactions[i]];
		const legend = locale.content.REACTIONS[reactions[i]];

		// Add to reactions
		reactionsArray.push({
			position: index,
			icon,
			legend,
			shouldAnimate,
		});
	}
	return reactionsArray;
};


/**
 * Update Visibility Handlers
 */

exports.setViewedUpdatesToLocalSchema = (storyId, updateIds, existingSchema) => {
	if (exports.isConsentLevelEnabled(CONSENT_PERFORMANCE_ENABLED_COOKIE)) {
		const updateSchema = exports.getLocalUpdateSchema(existingSchema);
		if (updateSchema[storyId] == null) updateSchema[storyId] = [];
		for (let i = 0; i < updateIds.length; i += 1) {
			if (updateSchema[storyId].indexOf(updateIds[i]) === -1) {
				updateSchema[storyId].push(updateIds[i]);
			}
		}
		const schema = JSON.stringify(updateSchema);
		document.cookie = `${UPDATES_SCHEMA_COOKIE}=${schema}; expires=Thu, 01 Jan 2100 00:00:00 UTC; path=/; SameSite=None; Secure`;
	}
};

exports.getLocalUpdateSchema = (schema) => {
	if (schema != null && Object.keys(schema).length > 0) return schema;
	const cookie = exports.getCookie(UPDATES_SCHEMA_COOKIE);
	try {
		if (cookie != null) return JSON.parse(cookie);
	} catch (error) {}
	return {};
};


/**
 * Modal Handlers
 */

exports.showModalWithId = (id) => {
	if (!$(`#${id}`).hasClass('show-modal')) {
		$(`#${id}`).addClass('show-modal');
	}
};

exports.hideModalWithId = (id) => {
	if ($(`#${id}`).hasClass('show-modal')) {
		$(`#${id}`).removeClass('show-modal');
	}
};


/**
 * Alert Handlers
 */

exports.showAlert = (message, id = 'input-alert') => {

	// Get elements
	const alert = $(`#${id}`);
	const alertText = $(`#${id}-text`);

	// Get alert visibility
	const isAlertVisible = alert.css('top') !== '0px';
	if (!isAlertVisible) {

		// Set alert content
		alertText.text(message);
		alertText.addClass('shadow');

		// Hide element
		setTimeout(() => { alert.addClass('solid-element'); }, 100);

		// Show alert
		alert.animate({ top: `-${alert.outerHeight() + 15}px` }, 300, null);

		// Scroll to top to see error
		if (id === 'input-alert') {
			if ($('html, body').scrollTop() > 130) {
				$('html, body').animate({ scrollTop: '130px' }, 300);
			}
		}
	}
};

exports.hideAlert = (id = 'input-alert') => {

	// Get elements
	const alert = $(`#${id}`);
	const alertText = $(`#${id}-text`);

	// Get alert visibility
	const isAlertVisible = alert.css('top') !== '0px';
	if (isAlertVisible) {

		// Set alert content
		alertText.removeClass('shadow');

		// Hide element
		setTimeout(() => {
			alert.removeClass('solid-element');
		}, 200);

		// Hide alert
		alert.animate({ top: '0px' }, 300, () => {

			// Remove content
			alertText.text('');
		});
	}
};
